import React, { useState, useRef, useEffect } from "react";
import "./CarsComponent.css";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import CarsData from "../../Data/cars.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faDoorOpen,
  faGears,
  faCheck,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Locations = [
  { code: "ZAG", name: "Zagreb" },
  { code: "SPU", name: "Split" },
  { code: "DBV", name: "Dubrovnik" },
  { code: "ZAD", name: "Zadar" },
  { code: "PUY", name: "Pula" },
  { code: "RJK", name: "Rijeka" },
  { code: "OSI", name: "Osijek" },
  { code: "TGR", name: "Trogir" },
  { code: "SIB", name: "Šibenik" },
  { code: "OMS", name: "Omiš" },
];

export default function CarsComponent() {
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [divIndex, setDivIndex] = useState(null);
  const [t] = useTranslation("common");
  const [phone, setPhone] = useState();
  const [minDateTime, setMinDateTime] = useState("");
  const [fromDateTime, setFromDateTime] = useState("");
  const form = useRef();

  useEffect(() => {
    const now = new Date();
    const formattedDateTime = now.toISOString().slice(0, 16);
    setMinDateTime(formattedDateTime);
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const toastId = toast.loading(t("pending"));
    const formData = new FormData(form.current);
    const formValues = {};
    formData.forEach((value, key) => {
      if (key === "from" || key === "to") {
        const date = new Date(value);
        const formattedDate = `${date.toLocaleDateString(
          "en-GB"
        )} ${date.toLocaleTimeString("en-GB")}`;
        formValues[key] = formattedDate;
      } else {
        formValues[key] = value;
      }
    });
    formValues["phone"] = phone;
    emailjs
      .send(
        "service_ozc5ltw",
        "template_ebs74o4",
        formValues,
        "QJZ0l-MpAJXhlJ4it"
      )
      .then(
        (result) => {
          emailjs
            .send(
              "service_ozc5ltw",
              "template_8qql6h9",
              formValues,
              "QJZ0l-MpAJXhlJ4it"
            )
            .then(
              (result) => {
                toast.success(t("success"), { id: toastId });
                setTimeout(goToMain, 3500);
              },
              (error) => {
                toast.error(t("errorMail"), { id: toastId });
              }
            );
        },
        (error) => {
          toast.error(t("errorMail"), { id: toastId });
        }
      );
  };

  function goToMain() {
    window.location.reload();
  }

  function deselectVehicle() {
    handleScrollToAdditionalDiv(selectedVehicle.id);
    setSelectedVehicle(null);
  }

  function handleClick(car, index) {
    const columnsPerRow = window.innerWidth >= 1100 ? 3 : 1;
    const row = Math.floor(index / columnsPerRow) + 1;
    setSelectedVehicle(car);
    setDivIndex(row);
    setTimeout(handleScrollToAdditionalDiv(index), 200);
  }

  function handleScrollToAdditionalDiv(index) {
    const about = document.getElementById(`car${index}`);
    if (about) {
      about.scrollIntoView({ behavior: "smooth" });
    }
  }

  const carContainers = CarsData.cars.map((car, index) => (
    <div
      id={`car${index}`}
      key={index}
      className="carContainer"
      onClick={() => handleClick(car, index)}
    >
      <div className="cardName">
        <p className="cardNameP">
          <b>{car.name}</b> {t("similar")}
        </p>
      </div>
      <div className="cardIcons">
        <div className="cardIconDiv">
          <FontAwesomeIcon icon={faUser} className="icon" />
          {car.seating}
        </div>
        <div className="cardIconDiv">
          <FontAwesomeIcon icon={faDoorOpen} className="icon" />
          {car.doors}
        </div>
        <div className="cardIconDiv">
          <FontAwesomeIcon icon={faGears} className="icon" />
          {car.transmission}
        </div>
      </div>
      <img className="cardImage" alt="" src={car.image_link} loading="lazy" />
      <div className="cardMileage">
        <FontAwesomeIcon icon={faCheck} className="icon" color="green" />
        <p className="cardNameP">{t("mileage")}</p>
      </div>
      <div className="cardMileage">
        <p className="cardNameP">
          <b>
            {car.price}€{t("day")}
          </b>
        </p>
      </div>
    </div>
  ));

  return (
    <div className="font centerDiv">
      <h1>{t("whichOne")}</h1>
      <div className="carsMainDiv">
        {carContainers}
        {selectedVehicle !== null && (
          <div
            className="additionalDiv"
            id="additionalDiv"
            style={{ gridRow: divIndex + 1 }}
          >
            <div className="imageDiv">
              {" "}
              <div className="carContainer2 whiteBG">
                <div className="cardName2">
                  <p className="cardNameP">
                    <b>{selectedVehicle.name}</b> {t("similar")}
                  </p>
                  <FontAwesomeIcon
                    icon={faX}
                    className="iconx"
                    onClick={() => deselectVehicle()}
                  />
                </div>
                <div className="cardIcons">
                  <div className="cardIconDiv">
                    <FontAwesomeIcon icon={faUser} className="icon" />
                    {selectedVehicle.seating}
                  </div>
                  <div className="cardIconDiv">
                    <FontAwesomeIcon icon={faDoorOpen} className="icon" />
                    {selectedVehicle.doors}
                  </div>
                  <div className="cardIconDiv">
                    <FontAwesomeIcon icon={faGears} className="icon" />
                    {selectedVehicle.transmission}
                  </div>
                </div>
                <img
                  className="cardImage"
                  alt=""
                  src={selectedVehicle.image_link}
                  loading="lazy"
                />
                <div className="cardMileage">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="icon"
                    color="green"
                  />
                  <p className="cardNameP">{t("mileage")}</p>
                </div>
                <div className="cardMileage">
                  <p className="cardNameP">
                    <b>
                      {selectedVehicle.price}€{t("day")}
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <div className="whiteBG fillerDiv">
              <p>
                <b className="underline">{t("info1")}</b> {t("info2")}
              </p>
              <p>
                <b className="underline">{t("info3")}</b> {t("info4")}
              </p>
              <p>
                <b className="underline">{t("info5")}</b> {t("info6")}
              </p>
            </div>
            <div className="carContainer2 whiteBG formDiv">
              <form className="form" ref={form} onSubmit={handleFormSubmit}>
                <label htmlFor="fullname">
                  <b>{t("name")}</b>
                </label>
                <input
                  className="input"
                  type="text"
                  id="fullname"
                  name="fullname"
                  required
                />
                <label htmlFor="email">
                  <b>{t("email")}</b>
                </label>
                <input
                  className="input"
                  type="email"
                  id="email"
                  name="email"
                  required
                />
                <label htmlFor="phone">
                  <b>{t("phone")}</b>
                </label>
                <PhoneInput
                  defaultCountry="HR"
                  value={phone}
                  onChange={setPhone}
                />
                <label htmlFor="from">
                  <b>{t("from")}</b>
                </label>
                <div className="dateLocation">
                  <input
                    min={minDateTime}
                    className="dateinput"
                    type="datetime-local"
                    id="from"
                    name="from"
                    onChange={(e) => setFromDateTime(e.target.value)}
                    required
                  />
                  <select
                    className="inputSelect"
                    defaultValue="SPU"
                    id="fromLocation"
                    name="fromLocation"
                    required
                  >
                    <option value="">{t("fromLocation")}</option>
                    {Locations.map((location) => (
                      <option key={location.code} value={location.name}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </div>
                <label htmlFor="to">
                  <b>{t("to")}</b>
                </label>
                <div className="dateLocation">
                  <input
                    className="dateinput"
                    type="datetime-local"
                    id="to"
                    name="to"
                    min={fromDateTime}
                    required
                  />
                  <select
                    className="inputSelect"
                    id="toLocation"
                    name="toLocation"
                    required
                  >
                    <option value="">{t("toLocation")}</option>
                    {Locations.map((location) => (
                      <option key={location.code} value={location.name}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </div>
                <label className="hideInput" htmlFor="selectedVehicle">
                  {t("vehicle")}
                </label>
                <input
                  className="hideInput input"
                  type="text"
                  id="selectedVehicle"
                  name="selectedVehicle"
                  value={selectedVehicle.name}
                  readOnly
                />
                <div>
                  {" "}
                  <button className="nextBtn" type="submit">
                    <b>{t("submit")}</b>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
